document.addEventListener("DOMContentLoaded", () => {
    const burgerButton = document.querySelector(".navbar__burger");
    const burgerClose = document.querySelector(".navbar__close");
    const burgerMenu = document.querySelector(".burger-menu");
    const linkWrappers = document.querySelectorAll(
        ".burger-menu__link-wrapper"
    );

    if (burgerButton == undefined) {
        return;
    }
    gsap.to(burgerMenu, {
        opacity: 0,
        display: "none",
    });
    burgerButton.addEventListener("click", () => {
        gsap.to(burgerButton, { display: "none", duration: 0 });
        gsap.to(burgerClose, { display: "block", duration: 0 });
        const tl = gsap.timeline();
        tl.to(burgerMenu, { display: "flex" });
        tl.to(burgerMenu, { opacity: 1 });
    });

    burgerClose.addEventListener("click", () => {
        gsap.to(burgerClose, { display: "none", duration: 0 });
        gsap.to(burgerButton, { display: "flex", duration: 0 });
        const tl = gsap.timeline();
        tl.to(burgerMenu, { opacity: 0 });
        tl.to(burgerMenu, { display: "none" });
    });

    linkWrappers.forEach((linkWrapper) => {
        linkWrapper.addEventListener("click", () => {
            gsap.to(burgerClose, { display: "none", duration: 0 });
            gsap.to(burgerButton, { display: "flex", duration: 0 });
            const tl = gsap.timeline();
            tl.to(burgerMenu, { opacity: 0 });
            tl.to(burgerMenu, { display: "none" });
        });
    });
});
