document.addEventListener("DOMContentLoaded", () => {
    return;
    // хз почему но этот код давал ошибку, когда на вид все работало
    const isMobileDevice = () => {
        return window.matchMedia("(max-width: 991px)").matches;
    };

    if (isMobileDevice()) {
        return;
    }
    const gri = document.querySelector(
        "#team > div.team__cards > div:nth-child(4)"
    );

    if (gri === null || gri === undefined) {
        return;
    }

    const canvas = gri.querySelector("canvas");
    const secret = gri.querySelector(".secret");
    canvas.addEventListener("mouseenter", () => {
        console.log("jhj");
        gsap.fromTo(
            secret,
            { x: 0 },
            { x: 10, duration: 0.05, repeat: 3, yoyo: true }
        );
    });
});
