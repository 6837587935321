document.addEventListener("DOMContentLoaded", () => {
    return;
    // Попросили удалить этот функционал, но я боюсь (и не хочу) удалять код

    const isMobileDevice = () => {
        return window.matchMedia("(max-width: 991px)").matches;
    };

    if (!isMobileDevice()) return;
    const initMobClients = () => {
        const clientsSections = document.querySelectorAll(".clients__section");
        if (clientsSections.length === 0) return;

        const navbarHeight = document.querySelector(".navbar").offsetHeight;
        const clientGrid = document.querySelector(".clients-grid");
        const clientGridCells = Array.from(
            clientGrid.querySelectorAll(".clients-grid__cell")
        );

        // Инициализация начального состояния
        gsap.set(clientGrid, { opacity: 0 });

        // Перемешивание массива
        const shuffleArray = (array) => {
            return array.sort(() => Math.random() - 0.5);
        };

        const shuffledCellsForCleaning = shuffleArray([...clientGridCells]);
        const shuffledCellsForFilling = shuffleArray([...clientGridCells]);

        // Фаза очистки
        const applyCleaningPhase = (progress) => {
            const totalCells = shuffledCellsForCleaning.length;
            const cleaningProgress = Math.max(0, progress / 0.25);
            const visibleCellsForCleaning = Math.floor(
                cleaningProgress * totalCells
            );

            shuffledCellsForCleaning.forEach((cell, index) => {
                cell.style.opacity = index < visibleCellsForCleaning ? 0 : 1;
            });
        };

        // Фаза заполнения
        const applyFillingPhase = (progress) => {
            const totalCells = shuffledCellsForFilling.length;
            const adjustedProgress = Math.max(0, (progress - 0.7) / 0.3);
            const visibleCells = Math.floor(adjustedProgress * totalCells);

            shuffledCellsForFilling.forEach((cell, index) => {
                cell.style.opacity = index < visibleCells ? 1 : 0;
            });
        };

        // Настройка ScrollTrigger для каждой секции клиентов
        clientsSections.forEach((section) => {
            ScrollTrigger.create({
                trigger: section,
                start: `top +=${navbarHeight}`,
                end: `+=${section.offsetHeight}`,
                pin: true,
                pinSpacing: false,
                onUpdate: (self) => {
                    const progress = self.progress;

                    if (progress <= 0.25) {
                        applyCleaningPhase(progress);
                    } else if (progress <= 0.7) {
                        shuffledCellsForCleaning.forEach((cell) => {
                            cell.style.opacity = 0;
                        });
                    } else {
                        applyFillingPhase(progress);
                    }
                },
            });
        });

        // Creating a timeline for the cleaning and filling phases
        const progressTimeline = gsap.timeline();

        // Общий ScrollTrigger для управления видимостью сетки клиентов
        ScrollTrigger.create({
            trigger: ".clients",
            start: "top top",
            end: () => {
                // Get the position of #team relative to the viewport
                const teamElement = document.querySelector(
                    "body > div.main.no_margin.no_margin-main > div.main__content > div:nth-child(4) > div.section__header-mobile"
                );
                const teamTop = teamElement.getBoundingClientRect().top;
                return `${teamTop - 100}`;
            },
            onEnter: () => {
                gsap.to(clientGrid, { opacity: 1 });
            },
            onEnterBack: () => {
                gsap.to(clientGrid, { opacity: 1 });
                progressTimeline.clear().to(
                    {},
                    {
                        duration: 1, // Duration for the filling phase
                        onUpdate: function () {
                            const progress = this.progress();
                            applyFillingPhase(progress);
                        },
                    }
                );
            },
            onLeave: () => {
                console.log("just left");
                progressTimeline.clear().to(
                    {},
                    {
                        duration: 1, // Duration for the cleaning phase
                        onUpdate: function () {
                            const progress = this.progress();
                            applyCleaningPhase(progress);
                        },
                    }
                );
            },
            onLeaveBack: () => {
                gsap.to(clientGrid, { opacity: 0 });
            },
        });
    };

    initMobClients();
});
