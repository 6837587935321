document.addEventListener("DOMContentLoaded", () => {
    const clients = document.querySelectorAll(".clients__section");

    const isMobileDevice = () => {
        return window.matchMedia("(max-width: 991px)").matches;
    };

    // Uncomment the following line if you want to avoid running the script on mobile devices
    // if (clients.length === 0 || isMobileDevice()) return;

    clients.forEach((section) => {
        const accordeons = section.querySelectorAll(".clients__accordeon");

        accordeons.forEach((accordeon) => {
            const body = accordeon.querySelector(
                ".clients__accordeon-body-wrapper"
            );

            accordeon.addEventListener("click", () => {
                const isOpen = accordeon.classList.contains("isOpen");

                // Close all accordions
                accordeons.forEach((otherAccordeon) => {
                    const otherBody = otherAccordeon.querySelector(
                        ".clients__accordeon-body-wrapper"
                    );

                    if (otherAccordeon !== accordeon) {
                        otherAccordeon.classList.remove("isOpen");
                        gsap.to(otherBody, {
                            height: 0,
                            padding: "0rem 0 0",
                            duration: 0.3,
                        });

                        // Optionally toggle visibility of .clients__decor div with gsap
                        const decor =
                            otherAccordeon.querySelector(".clients__decor");
                        if (decor) {
                            gsap.to(decor, {
                                opacity: 0,
                                duration: 0.3,
                            });
                        }
                    }
                });

                // Toggle the clicked accordion
                if (isOpen) {
                    accordeon.classList.remove("isOpen");
                    gsap.to(body, {
                        height: 0,
                        padding: "0rem 0 0",
                        duration: 0.3,
                    });

                    // Optionally toggle visibility of .clients__decor div with gsap
                    const decor = accordeon.querySelector(".clients__decor");
                    if (decor) {
                        gsap.to(decor, {
                            opacity: 0,
                            duration: 0.3,
                        });
                    }
                } else {
                    accordeon.classList.add("isOpen");
                    gsap.to(body, {
                        height: "100%",
                        padding: "3rem 0 0",
                        duration: 0.3,
                    });

                    // Optionally toggle visibility of .clients__decor div with gsap
                    const decor = accordeon.querySelector(".clients__decor");
                    if (decor) {
                        gsap.to(decor, {
                            opacity: 1,
                            duration: 0.3,
                        });
                    }
                }
            });
        });
    });
});
